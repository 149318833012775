import React from 'react'
import { Row, Col } from 'react-bootstrap'
import {
  CopyRight,
  CopyRightText,
  FooterContainer,
  FooterDes,
  FooterDeskGroupSocialIcon,
  FooterHeading,
  FooterIcon,
  FooterIconElement,
  FooterImage,
  FooterNeedHelp,
  FooterText,
  FooterTextEmail,
  FooterTextWebsite,
  GroupWebsite,
  FooterTextHeading,
  HrSeperate,
  RightCol,
  FaRegCopyrightIcon,
  WebsiteLink,
} from './FooterElements'
import {
  FaTelegram,
  FaFacebook,
  FaYoutube,
  FaFileAlt,
  FaEnvelopeOpen,
  FaGlobe,
  FaReddit,
  FaTwitter,
  FaMedium,
} from 'react-icons/fa'
import {RightNavFourElements} from '../Navbar/Data'
import {Logo} from '../Navbar/Data'

const Footer = () => {
  return (
    <>
      <FooterContainer>
        <Row className="pd-t-25">
          <Col md="1" lg="2" className="mg-t-30"></Col>
          <Col sm="12" xs="12" md="6" lg="5" className="mg-t-mn-10">
            <FooterHeading>
              <FooterImage src={Logo.img} />
              <FooterTextHeading>KittenCity</FooterTextHeading>
            </FooterHeading>
            <FooterDes>
              <FooterText>
              The 1st Metaverse NFT Game of your own adorable pets on Telegram (TON)
              </FooterText>
            </FooterDes>
            <GroupWebsite>
              <FooterIcon>
                <FaGlobe />
              </FooterIcon>
              <FooterTextWebsite>Website:</FooterTextWebsite>
              <FooterTextEmail>https://kittencity.fun</FooterTextEmail>
            </GroupWebsite>
            {/* <GroupWebsite>
              <FooterIcon>
                <FaEnvelopeOpen />
              </FooterIcon>
              <FooterTextWebsite>Email:</FooterTextWebsite>
              <FooterTextEmail>KittenCity@astop.tech</FooterTextEmail>
            </GroupWebsite> */}
          </Col>
          <RightCol sm="12" xs="12" md="4" lg="3">
            <Row>
              <FooterNeedHelp>Follow Us</FooterNeedHelp>
              <HrSeperate />
            </Row>
            <Row>
              <FooterDeskGroupSocialIcon>
                <FooterIconElement
                  to={{ pathname: RightNavFourElements.child.tele.link }}
                  target={'_blank'}
                  rel={'noopener noreferrer'}
                >
                  <FaTelegram className="footer-icon-telegram" />
                </FooterIconElement>
                <FooterIconElement
                  to={{ pathname: RightNavFourElements.child.twitter.link }}
                  target={'_blank'}
                >
                  <FaTwitter className="footer-icon-telegram" />
                </FooterIconElement>
                {/* <FooterIconElement
                  to={{ pathname: RightNavFourElements.child.fb.link }}
                  target={'_blank'}
                  rel={'noopener noreferrer'}
                >
                  <FaFacebook className="footer-icon-facebook" />
                </FooterIconElement> */}
                {/* <FooterIconElement
                  to={{ pathname: RightNavFourElements.child.youtube.link }}
                  target={'_blank'}
                  rel={'noopener noreferrer'}
                >
                  <FaYoutube className="footer-icon-youtube" />
                </FooterIconElement> */}
                {/* <FooterIconElement
                  to={{ pathname: RightNavFourElements.child.reddit.link }}
                  target={'_blank'}
                  rel={'noopener noreferrer'}
                >
                  <FaReddit className="footer-icon-reddit" />
                </FooterIconElement> */}
                {/* <FooterIconElement
                  to={{ pathname: RightNavFourElements.child.medium.link }}
                  target={'_blank'}
                  rel={'noopener noreferrer'}
                >
                  <FaMedium />
                </FooterIconElement> */}
                <FooterIconElement
                  to={{ pathname: RightNavFourElements.child.whitepaper.link }}
                  target={'_blank'}
                  rel={'noopener noreferrer'}
                >
                  <FaFileAlt className="footer-icon-whitepaper" />
                </FooterIconElement>
                <FooterIconElement
                  to={{ pathname: RightNavFourElements.child.pitchdesk.link }}
                  target={'_blank'}
                  rel={'noopener noreferrer'}
                >
                  <FaFileAlt className="footer-icon-whitepaper" />
                </FooterIconElement>
              </FooterDeskGroupSocialIcon>
            </Row>
          </RightCol>
        </Row>
      </FooterContainer>
      <CopyRight>
        <CopyRightText>
          Copyright 2024 <FaRegCopyrightIcon />{' '}
          <WebsiteLink to={'/'} rel={'noopener noreferrer'}>
          Kitten City - KittenCity.Fun
          </WebsiteLink>{' '}
          All Rights Reserved.
        </CopyRightText>
      </CopyRight>
    </>
  )
}

export default Footer
