export const RoadmapData = [
  {
    title: 'PHASE 1',
    timeLine: 'Oct, Nov - 2022',
    description: [
      'Idea conceptualization',
      'Talent acquisition',
      'Marketing Strategy',
      'Website development',
      'Airdrop game Beta version',
      'Airdrop Quest phase 1',
    ],
    isActive: true,
  },
  {
    title: 'PHASE 2',
    timeLine: 'Dec - 2022',
    description: [
      'Community Event',
      'Bug and error fixing',
      'Update new character, skill, item ',
      'Airdrop Quest phase 2',
    ],
    isActive: false,
  },
  {
    title: 'PHASE 3',
    timeLine: 'Jan - 2023',
    description: [
      'Partnership',
      'Marketing',
      'Development',
      'Full game release',
      'Airdrop Quest phase 3',
    ],
    isActive: false,
  },
  {
    title: 'PHASE 4',
    timeLine: 'Feb, Mar - 2023',
    description: [
      'Seed sale',
      'Private sale',
      'Pre-IDO',
      'IDO',
      'Token distribution',
      'MVP Release',
      'Beta release',
      'LP Staking',
      'Partner Staking',
      'Gamefication integration and testing',
      'Game official launch',
      'NFT Generation',
      'Marketplace Open',
    ],
    isActive: false,
  },
  // {
  //   title: 'PHASE 5',
  //   timeLine: 'Q2 - 2023',
  //   description: ['KittenCity on Others and crosschain NFT'],
  //   isActive: false,
  // },
  // {
  //   title: 'PHASE 6',
  //   timeLine: 'Q3 - 2023',
  //   description: ['First 3D version of KittenCity'],
  //   isActive: false,
  // },
]